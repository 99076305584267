















import { Component, Vue } from "vue-property-decorator";
import AppVue from "@/AppVue.vue";

@Component({
  components: {},
})
export default class OnboardingCpt extends AppVue {
  loading = false;

  mounted() {}

  async created() {}

  startTour() {
    this.$emit("start-tour");
  }
}
