







import { Component, Vue } from "vue-property-decorator";
import AppVue from "@/AppVue.vue";
import {
  WatchedProjectListCpt,
  WatchedCompanyListCpt,
} from "@/modules/tracking/components";

@Component({
  components: {
    WatchedCompanyListCpt: () => Promise.resolve(WatchedCompanyListCpt),
    WatchedProjectListCpt: () => Promise.resolve(WatchedProjectListCpt),
  },
})
export default class AllWatchesView extends AppVue {}
