import { render, staticRenderFns } from "./SavedSearchesView.vue?vue&type=template&id=5e98fa12&scoped=true&"
import script from "./SavedSearchesView.vue?vue&type=script&lang=ts&"
export * from "./SavedSearchesView.vue?vue&type=script&lang=ts&"
import style0 from "./SavedSearchesView.vue?vue&type=style&index=0&id=5e98fa12&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5e98fa12",
  null
  
)

export default component.exports