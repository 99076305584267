




















import { Component, Vue } from "vue-property-decorator";
import AppVue from "@/AppVue.vue";
import { MySavedSearchListCpt, PublicTagListCpt, IndexStatsCpt, OnboardingCpt } from "@/modules/dashboard/components";
import { WatchedProjectListCpt, WatchedCompanyListCpt } from "@/modules/tracking/components";
import { routeStack } from "@/core/services/routeStack.service";

@Component({
  components: {
    MySavedSearchListCpt,
    WatchedProjectListCpt,
    WatchedCompanyListCpt,
    IndexStatsCpt,
    OnboardingCpt,
    PublicTagListCpt,
  },
  beforeRouteEnter(from, to, next) {
    routeStack.clear();
    next();
  },
})
export default class DashboardView extends AppVue {
  mounted() {
    super.updateTitle("Dashboard");
  }
}
