




















import { Component, Prop, Vue } from "vue-property-decorator";
import AppVue from "@/AppVue.vue";
import { MySavedSearchListCpt, MyOldSavedSearchListCpt } from "@/modules/dashboard/components";
import SavedSearchTableCpt from "./components/SavedSearchTableCpt.vue";
import { SavedCompanySearchService, SavedSearchService, SearchService } from "@/core/services";
import { SavedSearchModel } from "@/core/models";

@Component({
  components: {
    MySavedSearchListCpt,
    SavedSearchTableCpt: () => Promise.resolve(SavedSearchTableCpt),
    MyOldSavedSearchListCpt: () => Promise.resolve(MyOldSavedSearchListCpt),
  },
})
export default class SavedSearchDashboardView extends AppVue {
  @Prop({ default: false }) project: boolean;
  @Prop({ default: false }) company: boolean;
  @Prop({ default: false }) upgrade: boolean;
  @Prop({ default: true }) autoload: boolean;
  @Prop({ default: false }) filters: boolean;
  projects: SavedSearchModel[] = [];
  companies: SavedSearchModel[] = [];
  max = 100;
  loading = false;
  async mounted() {
    await this.initializeModel();
  }
  updateProjects(model: SavedSearchModel) {
    const row = this.projects.find((x) => x.userAzureSearchID === model.userAzureSearchID);
    if (!row) {
      return;
    }
    row.name = model.name;
  }
  updateCompanies(model: SavedSearchModel) {
    const row = this.companies.find((x) => x.userAzureSearchID === model.userAzureSearchID);
    if (!row) {
      return;
    }
    row.name = model.name;
  }
  private async initializeModel() {
    this.loading = true;
    try {
      this.projects = (await SavedSearchService.list(this.max)).map((x) => {
        x.isLoading = this.autoload;
        return x;
      });
      this.companies = (await SavedCompanySearchService.list(this.max)).map((x) => {
        x.isLoading = this.autoload;
        return x;
      });
      if (this.autoload) {
        await this.runSearches();
      }
    } finally {
      this.loading = false;
    }
  }
  private async runSearches() {
    this.companies.forEach(async (x) => {
      x.isLoading = true;
      const searchFilter = await SavedSearchService.getSingle(x.userAzureSearchID);
      x.searchModel = searchFilter;
      const results = await SearchService.searchCompany(searchFilter.freeText, 0, 0, searchFilter, "");
      x.updates = results.totalRecords;
      x.isLoading = false;
    });
    this.projects.forEach(async (x) => {
      x.isLoading = true;
      const searchFilter = await SavedSearchService.getSingle(x.userAzureSearchID);
      x.searchModel = searchFilter;
      const results = await SearchService.search(searchFilter.freeText, 0, 0, searchFilter, [], "");
      x.updates = results.totalRecords;
      x.isLoading = false;
    });
  }
}
