







































import { Component, Prop, Vue } from "vue-property-decorator";
import AppVue from "@/AppVue.vue";
import { SectorIconCpt } from "@/core/components";
import { ProjectModel, ProjectPhotoModel } from "@/core/models";

@Component({
  components: {
    SectorIconCpt,
  },
})
export default class HighlightedProjectCpt extends AppVue {
  @Prop({ default: "" }) title: string;
  @Prop({ default: "" }) stage: string;
  @Prop({ default: "" }) photos: ProjectPhotoModel[];
  @Prop({ default: "" }) project: ProjectModel;
  @Prop({ default: new Date() }) updated: Date;
  @Prop({ default: 0 }) id: number;
  @Prop({ default: "" }) sector: string;
  loading = false;

  get address() {
    if (!this.project.addressSeparated.line5) {
      return this.project.county;
    }

    return [this.project.addressSeparated.line5, this.project.county].join(
      ", ",
    );
  }

  get promoter() {
    return this.project.companies.find((c) => c.roleName === "Promoter").name;
  }

  async created() {
    this.initializeModel();
  }

  private async initializeModel() {
    // this.loading = true;
    // try {
    //   this.model = await SavedSearchService.listCisOnline();
    // } finally {
    //   this.loading = false;
    // }
  }
}
