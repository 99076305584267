
















































































































import { Component, Vue, Prop, Watch } from "vue-property-decorator";

import { SavedCompanySearchService, SavedSearchService } from "@/core/services";
import {} from "@/core/components";

import AppVue from "@/AppVue.vue";
import { SavedSearchModel } from "@/core/models";
import { ElNotificationOptions } from "element-ui/types/notification";
import { SearchFilterSummaryCpt } from "@/modules/search";

@Component({
  components: {
    SearchFilterSummaryCpt: () => Promise.resolve(SearchFilterSummaryCpt),
  },
})
export default class SavedSearchTableCpt extends AppVue {
  @Prop({ default: true }) showDelete: boolean;
  @Prop({ default: () => [] }) models: SavedSearchModel[];
  @Prop({ default: false }) isCompanySearch: boolean;
  @Prop({ default: false }) filters: boolean;
  newName = "";
  loading: boolean = false;
  renamingId = 0;
  renameSearchPopup = false;
  get isLoading() {
    return this.loading;
  }

  async created() {}

  async mounted() {}
  endRename(hideId: number) {
    if (hideId === this.renamingId) {
      this.newName = "";
      this.renamingId = 0;
    }
  }
  startRename(model: SavedSearchModel) {
    this.newName = model.name;
    this.renamingId = model.userAzureSearchID;
  }
  async renameSearch(model: any, newName: string) {
    if (model.userAzureSearchID === this.renamingId) {
      let res = new SavedSearchModel();
      if (!this.isCompanySearch) {
        res = await SavedSearchService.renameSavedSearch(this.renamingId, this.newName);
      } else {
        res = await SavedCompanySearchService.renameSavedSearch(this.renamingId, this.newName);
      }
      this.$emit("updated", res);
      model.isRenaming = false;
    }
  }
  private async toggleAlert(searchId: number, name: string, value: boolean) {
    await SavedSearchService.toggleAlert(searchId, value);
    const operationType = `${value ? "enabled" : "disabled"}`;
    const message: ElNotificationOptions = {
      title: `Alert ${operationType}`,
      message: `Alert for search "${name}" ${operationType}`,
      type: "success",
    };
    this.$notify(message);
  }
  private async runSearch(searchId: number) {
    this.$emit("click");

    if (searchId !== Number(this.$route.params.filterId)) {
      if (!this.isCompanySearch) {
        this.$router
          .push({
            name: "SearchProjects",
            params: {
              projectsLoaded: "100",
              filterId: searchId.toString(),
              clearCache: "1",
            },
          })
          .catch(() => {});
      } else {
        this.$router
          .push({
            name: "SearchCompanies",
            params: {
              projectsLoaded: "100",
              filterId: searchId.toString(),
              clearCache: "1",
            },
          })
          .catch(() => {});
      }
    }
  }
  private async renameProjectSearch(searchId: number, newName: string) {
    try {
      this.loading = true;
      let updatedSearch = new SavedSearchModel();
      if (this.isCompanySearch) {
        updatedSearch = await SavedCompanySearchService.renameSavedSearch(searchId, newName);
      } else {
        updatedSearch = await SavedSearchService.renameSavedSearch(searchId, newName);
      }
      const index = this.models.findIndex((x) => x.userAzureSearchID === searchId);
      this.models[index] = updatedSearch;
      const messageObj: any = {
        title: "Renamed",
        message: "Search renamed successfully",
        type: "success",
      };
      this.$notify(messageObj);
    } finally {
      this.loading = false;
    }
  }

  private async deleteSearch(searchId: number) {
    try {
      this.loading = true;
      await SavedSearchService.delete(searchId);
      const index = this.models.findIndex((x) => x.userAzureSearchID === searchId);
      this.models.splice(index, 1);

      const messageObj: any = {
        title: "Deleted",
        message: "Saved search deleted",
        type: "success",
      };
      this.$notify(messageObj);
    } finally {
      this.loading = false;
    }
  }
}
