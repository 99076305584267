











import { Component, Vue } from "vue-property-decorator";
import AppVue from "@/AppVue.vue";
import { MySavedSearchListCpt } from "@/modules/dashboard/components";

@Component({
  components: {
    MySavedSearchListCpt,
  },
})
export default class SavedSearchesView extends AppVue {}
