

































import { Component, Vue } from "vue-property-decorator";
import AppVue from "@/AppVue.vue";
import { DashboardService, ProjectService } from "@/core/services";
import { DashboardModel, ProjectModel } from "@/core/models";
import HighlightedProjectCpt from "./HighlightedProjectCpt.vue";
import * as pbi from "powerbi-client";
import { IEmbedConfigurationBase } from "embed";

@Component({
  components: {
    HighlightedProjectCpt,
  },
})
export default class IndexStatsCpt extends AppVue {
  loading = false;
  model: DashboardModel = {} as DashboardModel;
  highlighted = [] as ProjectModel[];
  report: any = {};
  showDialog: boolean = false;

  async created() {
    this.loading = true;
    this.model = await DashboardService.stats();
    try {
      const projects = await ProjectService.getHighlightedProjects(5);
      if (projects) {
        this.highlighted = projects;
      }
    } catch (err) {
    } finally {
      this.loading = false;
    }

    const embedConfiguration: pbi.IEmbedConfiguration = {
      type: "report",
      accessToken:
        "eyJrIjoiMWI3YTljMjctZDE2Yi00MDk4LWIyOGUtM2FlYzc4NzUzNTQyIiwidCI6IjA3ZTNmNmEzLWExNzctNDM3OS05MTljLWE4NDMwMDY3ZmEwMyIsImMiOjh9",
      id: "c6b15e44-23f8-4fa0-8306-ee0cfb49630b",
      pageName: "ReportSection324bcb9ca3d30067c89e",
      pageView: "fitToWidth",
      viewMode: pbi.models.ViewMode.View,
      embedUrl:
        "https://app.powerbi.com/view?r=eyJrIjoiMWI3YTljMjctZDE2Yi00MDk4LWIyOGUtM2FlYzc4NzUzNTQyIiwidCI6IjA3ZTNmNmEzLWExNzctNDM3OS05MTljLWE4NDMwMDY3ZmEwMyIsImMiOjh9&pageName=ReportSection324bcb9ca3d30067c89e&hidenavbar=true",
      settings: {
        filterPaneEnabled: false,
        navContentPaneEnabled: false,
        background: pbi.models.BackgroundType.Transparent,
        layoutType: pbi.models.LayoutType.MobilePortrait,
        panes: {
          bookmarks: {
            visible: false,
          },
          fields: {
            expanded: false,
          },
          filters: {
            expanded: false,
            visible: true,
          },
          pageNavigation: {
            visible: false,
          },
          selection: {
            visible: false,
          },
          syncSlicers: {
            visible: false,
          },
          visualizations: {
            expanded: false,
          },
        },
      },
    };
    const powerbi = new pbi.service.Service(
      pbi.factories.hpmFactory,
      pbi.factories.wpmpFactory,
      pbi.factories.routerFactory,
    );
    embedConfiguration.permissions = pbi.models.Permissions.Read;
    const reportContainer = document.getElementById("reportContainer");
    if (reportContainer == null) {
      return;
    }
    this.report = powerbi.embed(reportContainer, embedConfiguration);

    // this.loadInsightsReport();
  }

  getSector(categories: Array<any>): string {
    if (!categories || categories.length == 0 || !categories[0]) return "";
    return categories[0].sector as string;
  }

  loadInsightsReport() {
    const embedConfiguration: pbi.IEmbedConfiguration = {
      type: "report",
      accessToken:
        "eyJrIjoiMWI3YTljMjctZDE2Yi00MDk4LWIyOGUtM2FlYzc4NzUzNTQyIiwidCI6IjA3ZTNmNmEzLWExNzctNDM3OS05MTljLWE4NDMwMDY3ZmEwMyIsImMiOjh9",
      id: "c6b15e44-23f8-4fa0-8306-ee0cfb49630b",
      pageName: "ReportSection324bcb9ca3d30067c89e",
      pageView: "fitToWidth",
      viewMode: pbi.models.ViewMode.View,
      embedUrl:
        "https://app.powerbi.com/view?r=eyJrIjoiMWI3YTljMjctZDE2Yi00MDk4LWIyOGUtM2FlYzc4NzUzNTQyIiwidCI6IjA3ZTNmNmEzLWExNzctNDM3OS05MTljLWE4NDMwMDY3ZmEwMyIsImMiOjh9",
      settings: {
        filterPaneEnabled: false,
        navContentPaneEnabled: false,
        background: pbi.models.BackgroundType.Transparent,
        layoutType: pbi.models.LayoutType.MobilePortrait,
        panes: {
          bookmarks: {
            visible: false,
          },
          fields: {
            expanded: false,
          },
          filters: {
            expanded: false,
            visible: true,
          },
          pageNavigation: {
            visible: false,
          },
          selection: {
            visible: false,
          },
          syncSlicers: {
            visible: false,
          },
          visualizations: {
            expanded: false,
          },
        },
      },
    };
    const powerbi = new pbi.service.Service(
      pbi.factories.hpmFactory,
      pbi.factories.wpmpFactory,
      pbi.factories.routerFactory,
    );
    embedConfiguration.permissions = pbi.models.Permissions.Read;
    const reportContainer = document.getElementById("report2Container");
    if (reportContainer == null) {
      return;
    }
    this.report = powerbi.embed(reportContainer, embedConfiguration);
  }
}
