



















































































































































import { Component, Vue, Prop } from "vue-property-decorator";
import AppVue from "@/AppVue.vue";
import { CurrentProfile, SavedSearchService, SavedCompanySearchService } from "@/core/services";
import { SearchService } from "@/core/services";
import { BlankSlateCpt } from "@/core/components";
import { CISOnlineSavedSearchModel, SavedSearchModel, SearchModel } from "@/core/models";
import EventBus from "@/core/components/this.$eventHub.vue";
import SavedSearchTableCpt from "./SavedSearchTableCpt.vue";
import { SearchFilterSummaryCpt } from "@/modules/search";

@Component({
  components: {
    BlankSlateCpt,
    SavedSearchTableCpt: () => Promise.resolve(SavedSearchTableCpt),
    SearchFilterSummaryCpt: () => Promise.resolve(SearchFilterSummaryCpt),
  },
})
export default class MySavedSearchListCpt extends AppVue {
  @Prop({ default: 5 }) max: number;
  @Prop({ default: false }) showDelete: boolean;
  @Prop({ default: true }) showViewAll: boolean;

  loading = false;
  model: SavedSearchModel[] = [];
  companies: SavedSearchModel[] = [];
  oldSearches: CISOnlineSavedSearchModel[] = [];
  newSavedSearches: SavedSearchModel[] = [];
  rowClass(currentId: number) {
    const classObject = {
      "saved-search": true,
      selected: this.$route && this.$route.params && currentId === Number(this.$route.params.filterId),
    };
    return classObject;
  }
  mounted() {}

  get savedSearchesToUpgrade() {
    let notUpgradedCount = 0;
    this.oldSearches.forEach((os) => {
      if (this.newSavedSearches.filter((x) => x.upgradedUserSearchID === os.userSearchID).length) {
        notUpgradedCount += 1;
      }
    });
    return this.oldSearches.length - notUpgradedCount;
  }

  async created() {
    this.$eventHub.$on("savedSearchListUpdated", this.initializeModel);
    this.initializeModel().then(() => {
      this.runSearches();
      this.getSearchModels().then(() => {
        this.loading = false;
      });
    });

    // load all new searches to compare with old
    this.newSavedSearches = await SavedSearchService.list(0);
    this.oldSearches = await SavedSearchService.listCisOnline();
  }

  destroyed() {
    this.$eventHub.$off("savedSearchListUpdated", this.initializeModel);
  }

  startSearching() {
    this.$router
      .push({
        name: CurrentProfile.currentProfile?.defaultView == 2 ? "TableSearchProjects" : "SearchProjects",
        params: {
          projectsLoaded: "100",
          clearCache: "1",
        },
      })
      .catch(() => {});
  }

  startSearchingCompanies() {
    this.$router
      .push({
        name: "SearchCompanies",
        params: {
          resultsLoaded: "100",
          clearCache: "1",
        },
      })
      .catch(() => {});
  }

  private async getSearchModels() {
    this.model.forEach(this.getSearchModel);
    this.companies.forEach(this.getSearchModel);
  }

  private async getSearchModel(savedSearchModel: SavedSearchModel, ix: number) {
    const searchModel = await SavedSearchService.getSingle(savedSearchModel.userAzureSearchID);
    if (savedSearchModel.searchType === 0) {
      this.model[ix].searchModel = searchModel;
    }
    if (savedSearchModel.searchType === 1) {
      this.companies[ix].searchModel = searchModel;
    }
  }

  private async toggleAlert(searchId: number, value: boolean) {
    await SavedSearchService.toggleAlert(searchId, value);
    const alertObject = this.model.find((x) => x.userAzureSearchID === searchId);
    if (!!alertObject) {
      alertObject.isAlertEnabled = value;
    }
  }

  private async runSearch(searchId: number) {
    this.$emit("click");
    if (searchId !== Number(this.$route.params.filterId)) {
      this.$router
        .push({
          name: CurrentProfile.currentProfile?.defaultView == 2 ? "TableSearchProjects" : "SearchProjects",
          params: {
            projectsLoaded: "100",
            filterId: searchId.toString(),
            clearCache: "1",
          },
        })
        .catch(() => {});
    }
  }

  private async runCompanySearch(searchId: number) {
    this.$emit("click");
    if (searchId !== Number(this.$route.params.filterId)) {
      this.$router
        .push({
          name: "SearchCompanies",
          params: {
            resultsLoaded: "100",
            filterId: searchId.toString(),
            clearCache: "1",
          },
        })
        .catch(() => {});
    }
  }

  private async runSearches() {
    this.model.forEach(this.runSavedSearch);
    this.companies.forEach(this.runSavedSearch);
  }

  private async runSavedSearch(searchModel: SavedSearchModel, ix: number) {
    this.model[ix].isLoading = false;
    const searchFilter = await SavedSearchService.getSingle(searchModel.userAzureSearchID);
    if (searchModel.searchType === 0) {
      this.model[ix].isLoading = true;
      this.$nextTick(async () => {
        const results = await SearchService.search(searchFilter.freeText, 0, 0, searchFilter, [], "");
        this.model[ix].updates = results.totalRecords;
        this.model[ix].isLoading = false;
      });
    }

    if (searchModel.searchType === 1) {
      this.companies[ix].isLoading = true;
      this.$nextTick(async () => {
        const results = await SearchService.searchCompany(searchFilter.freeText, 0, 0, searchFilter, "");
        this.companies[ix].updates = results.totalRecords;
        this.companies[ix].isLoading = false;
      });
    }
  }

  private async initializeModel() {
    this.loading = true;
    try {
      this.model = await SavedSearchService.list(this.max);
      this.companies = await SavedCompanySearchService.list(this.max);
    } finally {
      this.loading = false;
    }
  }
}
