import {
  DashboardView,
  SavedSearchDashboardView,
  AllWatchesView,
} from "@/modules/dashboard";
import { SavedSearchMenuCpt, MyOldSavedSearchListCpt } from "@/modules/dashboard/components";
import { AccountMenuCpt } from "../account";

export const dashboardRoutes = [
  {
    path: "/dashboard",
    name: "dashboard",
    component: DashboardView,
  },
  {
    path: "/",
    redirect: "/dashboard",
  },

  {
    path: "/dashboard/saved/searches",
    redirect: "/dashboard/saved/searches/projects",
    name: "saved-searches",
  },
  {
    path: "/dashboard/saved/searches/projects",
    name: "saved-searches-projects",
    components: {
      leftmenu: SavedSearchMenuCpt,
      content: SavedSearchDashboardView,
    },
    props: {
      leftmenu: () => ({ title: "Saved searches" }),
      content: () => ({ project: true, company: false, upgrade: false, filters: true }),
    },
    meta: { layout: "leftbar" },
  },
  {
    path: "/dashboard/saved/searches/company",
    name: "saved-searches-companies",
    components: {
      leftmenu: SavedSearchMenuCpt,
      content: SavedSearchDashboardView,
    },
    props: {
      leftmenu: () => ({ title: "Saved searches" }),
      content: () => ({ project: false, company: true, upgrade: false, filters: true }),
    },
    meta: { layout: "leftbar" },
  },
  {
    path: "/dashboard/saved/searches/upgrade",
    name: "saved-searches-upgrade",
    components: {
      leftmenu: SavedSearchMenuCpt,
      content: SavedSearchDashboardView,
    },
    props: {
      leftmenu: () => ({ title: "Upgrade your old searches" }),
      content: () => ({ project: false, company: false, upgrade: true }),
    },
    meta: { layout: "leftbar" },
  },
  {
    path: "/dashboard/watches",
    name: "watches",
    component: AllWatchesView,
    meta: { layout: "basic" },
  },
];
