























import { Component, Vue, Prop } from "vue-property-decorator";

import { SearchService, TurnstoneLookupsModel } from "@/core/services";
import {} from "@/core/components";

import AppVue from "@/AppVue.vue";

export class Type {}

@Component({
  components: {},
})
export default class PublicTagListCpt extends AppVue {
  lookups: TurnstoneLookupsModel = new TurnstoneLookupsModel();
  async created() {
    this.lookups = await SearchService.getLookups();
  }
  async mounted() {}
}
