























import { Component, Vue, Prop } from "vue-property-decorator";

import {} from "@/core/services";
import {} from "@/core/components";

import AppVue from "@/AppVue.vue";

export class Type {}

@Component({
  components: {},
})
export default class SavedSearchMenuCpt extends AppVue {
  @Prop({ default: false }) loading: boolean;
  @Prop({ default: "Menu" }) title: string;
  isActive(routeName: string) {
    return this.$route.name === routeName;
  }
  get isLoading() {
    return this.loading;
  }

  async created() {}

  async mounted() {}
}
