































































































































import { Component, Vue } from "vue-property-decorator";
import AppVue from "@/AppVue.vue";
import { SavedCompanySearchService, SavedSearchService } from "@/core/services";
import { CISOnlineSavedSearchModel, SearchModel, SavedSearchModel } from "@/core/models";

@Component({
  components: {},
})
export default class MyOldSavedSearchListCpt extends AppVue {
  loading = false;
  model: CISOnlineSavedSearchModel[] = [];
  searchToSave: SearchModel = new SearchModel();
  upgradedSearch: SavedSearchModel = new SavedSearchModel();
  savedSearches: SavedSearchModel[] = [];
  dialogVisible: boolean = false;
  dialogUpgradeVisible: boolean = false;
  upgradeLoading: boolean = false;

  async runSearch(search: SavedSearchModel) {
    if (search.searchType === 1) {
      // Company search
      this.$router
        .push({
          name: "SearchCompanies",
          params: {
            resultsLoaded: "100",
            filterId: search.userAzureSearchID.toString(),
            clearCache: "1",
          },
        })
        .catch(() => {});
    } else {
      this.$router
        .push({
          name: "SearchProjects",
          params: {
            projectsLoaded: "100",
            filterId: search.userAzureSearchID.toString(),
            clearCache: "1",
          },
        })
        .catch(() => {});
    }
  }
  async created() {
    this.initializeModel();
  }
  isUpgraded(oldSearchId: number) {
    return this.savedSearches.filter((x) => x.upgradedUserSearchID === oldSearchId).length;
  }

  getType(row: CISOnlineSavedSearchModel) {
    switch (row.entityTypeId) {
      case 1:
        return "Project";
      case 2:
        return "Company";
      case 103:
        return "Self-build";
    }

    return "Unknown";
  }

  async loadSearch(row: CISOnlineSavedSearchModel) {
    this.searchToSave = await SavedSearchService.getSingleCisOnline(row.userSearchID);
    this.searchToSave.name = row.name;

    this.dialogVisible = true;
  }
  handleUpgradeClose() {
    this.dialogUpgradeVisible = false;
    this.searchToSave = new SearchModel();
    this.upgradedSearch = new SavedSearchModel();
  }
  handleClose() {
    this.dialogVisible = false;
    this.searchToSave = new SearchModel();
    this.upgradedSearch = new SavedSearchModel();
  }

  async upgradeSearch() {
    try {
      this.upgradeLoading = true;
      this.dialogVisible = false;
      this.dialogUpgradeVisible = true;
      this.searchToSave.isUpgradedSearch = true;
      if (this.searchToSave.searchType === 0) {
        // Project search
        this.upgradedSearch = await SavedSearchService.save(this.searchToSave);
      } else {
        // Company search
        this.upgradedSearch = await SavedCompanySearchService.save(this.searchToSave);
      }
    } finally {
      this.upgradeLoading = false;
    }
  }

  private async initializeModel() {
    this.loading = true;
    try {
      this.savedSearches = [...(await SavedSearchService.list(0)), ...(await SavedCompanySearchService.list(0))];
      this.model = await SavedSearchService.listCisOnline();
    } finally {
      this.loading = false;
    }
  }

  private async deleteSearch(searchId: number) {
    try {
      this.loading = true;
      await SavedSearchService.deleteCISOnlineSearch(searchId);
      const index = this.model.findIndex((x) => x.userSearchID === searchId);
      this.model.splice(index, 1);

      const messageObj: any = {
        title: "Deleted",
        message: "Saved search deleted",
        type: "success",
      };
      this.$notify(messageObj);
    } finally {
      this.loading = false;
    }
  }
}
