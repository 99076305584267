






























import { Component, Vue } from "vue-property-decorator";
import AppVue from "@/AppVue.vue";
import { AlertService, AlertModel } from "@/core/services";

@Component({
  components: {},
})
export default class LatestAlertsCpt extends AppVue {
  loading = false;
  model: AlertModel[] = [];

  mounted() {}

  async created() {
    this.initializeModel();
  }

  private async initializeModel() {
    this.loading = true;
    try {
      this.model = await AlertService.getLatest(5);
    } finally {
      this.loading = false;
    }
  }
}
